import { getLocale } from '@/utils/LocaleUtils'

export const acceptedLanguageCodes: readonly (typeof Intl.Locale.prototype.language)[] = ['de', 'fr', 'it']

export function isAcceptedLanguageCode(languageCode: string): boolean {
  return acceptedLanguageCodes.includes(languageCode.toLowerCase())
}

/** Returns language of user */
export function getLanguage(): (typeof acceptedLanguageCodes)[number] {
  return getLocale().language
}
