import { isValidString } from '@/utils/TypeGuards'
import { acceptedLanguageCodes, isAcceptedLanguageCode } from '@/utils/LanguageUtils'

/** Returns the country code from {@link window.location.pathname} if available otherwise undefined. */
export function getCountryCodeFromPath(): (typeof acceptedLanguageCodes)[number] | undefined {
  const countryCodeInPath: string | undefined = window.location.pathname.split('/')[1]
  if (isValidString(countryCodeInPath) && isAcceptedLanguageCode(countryCodeInPath)) {
    return countryCodeInPath
  }
  return undefined
}
